import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { AltSiteName, NewsBaseURL } from "../../utils";
// import Slider from "react-slick";
import { RecentNews } from "../../../queries/common_use_query";
import DateFormat from "../../format-date";
import "./Insights.scss";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {
  isMobileOnly,
  isTablet,
  isMobile
} from "react-device-detect";

import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
const settings = {
  dots: false,
  speed: 800,
  infinite: true,
  arrows: true,
  mobileFirst: true,
  centerMode: false,
  responsive: [
    {
      breakpoint: 3020,
      settings: {
        arrows: true,
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        arrows: true,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 991,
      settings: {
        arrows: false,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 420,
      settings: {
        arrows: false,
        slidesToShow: 1,
        dots: true,
      },
    },
  ],
};
const Insights = () => {
  const {loading:loading, error:error, data:data} = RecentNews();
  return (
    <React.Fragment>
      <section className="insights-row">
        <Container>
          <Row>
            <Col className="d-flex align-item-center">
              <div className="insights-title">
                <h2>Insights</h2>
              </div>
              <div className="ms-auto d-flex align-items-center">
                <Link to="/about/latest-property-news/" className="link-text d-none d-lg-inline-block">
                  All news
                </Link>
                <Link to="/about/latest-property-news/">
                <button type="button" className="btn-circle">
                  <i className="icon-arrow"></i>                
                </button>
                </Link>
              </div>
            </Col>
          </Row>
          <div className="insights-slider">
         
          <CarouselProvider
        naturalSlideWidth={100}        
        naturalSlideHeight={(isMobileOnly) ? 150 : 150}
        totalSlides={data?.newsBlogs?.length}
        infinite={true}
        touchEnabled={true}
        dragEnabled={true}
        isPlaying={false}
        visibleSlides={(isMobileOnly) ? 1.2 : (isTablet) ? 2 : 3.2}
        className="banner-imgslider d-block"
      >
<Slider className="banner-imgslider d-block">
{/* <div className="slick-arrow slick-prev" > <ButtonBack/></div>
<div className="slick-arrow slick-next"> <ButtonNext/></div> */}

{data && data.newsBlogs.length > 0 && data.newsBlogs.map((item, index) => {
            let processedImages = JSON.stringify({});
            if (item?.imagetransforms?.Tile_Image_Transforms) {
            processedImages = item.imagetransforms.Tile_Image_Transforms;
            }
            return<>
                          <Slide key={index} index={index} className="home-page-slider-item">

              <div className="insight-item">
                <Link  to={`${NewsBaseURL}/${item.Select_News_Category.URL}/${item.URL}/`} className="img-zoom">
                <ImageTransform imagesources={item.Tile_Image.url} renderer="srcSet" imagename="news-blogs.Tile_Image.commontile" attr={{ alt: item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Title+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>             
                </Link>
                <div className="insights-block">
                  {/* <DateFormat date={item.News_Published_Date}/> */}
                  <h3>
                    <Link to={`${NewsBaseURL}/${item.Select_News_Category.URL}/${item.URL}/`}>
                      {" "}
                      {item.Title}
                    </Link>
                  </h3>
                </div>
              </div>
              </Slide>
            </>
            })}

</Slider>
<div className="slick-arrow slick-prev">
<ButtonBack/>
</div>
<div className="slick-arrow slick-next">
<ButtonNext/>
</div>
{isMobile &&
        <DotGroup 
          dotNumbers={false}
          disableActiveDots={true}
          showAsSelectedForCurrentSlideOnly={true}
        />
        } 

</CarouselProvider>


            {/* <Slider className="banner-imgslider d-block" {...settings}>
            {data && data.newsBlogs.length > 0 && data.newsBlogs.map((item, index) => {
            let processedImages = JSON.stringify({});
            if (item?.imagetransforms?.Tile_Image_Transforms) {
            processedImages = item.imagetransforms.Tile_Image_Transforms;
            }
            return<>
              <div className="insight-item">
                <a href={`${NewsBaseURL}/${item.Select_News_Category.URL}/${item.URL}`} className="img-zoom">
                <ImageTransform imagesources={item.Tile_Image.url} renderer="srcSet" imagename="news-blogs.Tile_Image.commontile" attr={{ alt: item.Tile_Image.alternativeText?item.Tile_Image.alternativeText:item.Title+AltSiteName}} imagetransformresult={processedImages} id={item.id}/>             
                </a>
                <div className="insights-block">
                  <DateFormat date={item.News_Published_Date}/>
                  <h3>
                    <Link to={`${NewsBaseURL}/${item.Select_News_Category.URL}/${item.URL}`}>
                      {" "}
                      {item.Title}
                    </Link>
                  </h3>
                </div>
              </div>
            </>
            })}
              
            </Slider> */}
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Insights;
